import "css/tailwind.css";

import { useState } from "react";

import type { AppProps } from "next/app";
import Script from "next/script";

import { captureException } from "@sentry/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ErrorBoundary } from "react-error-boundary";

import ErrorPage from "pages/_error.page";

import Analytics from "components/Analytics";
import ConsentProvider from "components/ConsentProvider";
import CookieBar from "components/CookieBar";

function BreinspokenApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 5 * 60 * 1000, // 5 minutes
            retry: (failureCount: number, error: unknown) => {
              if (process.env.NODE_ENV !== "production") {
                return false;
              }

              // Retry (max. 3 times) only if network error detected
              if (
                error instanceof AxiosError &&
                error.message === "Network request failed" &&
                failureCount <= 3
              ) {
                return true;
              }

              return false;
            },
          },
        },
      }),
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorPage} onError={error => captureException(error)}>
      <QueryClientProvider client={queryClient}>
        {!!process.env.NEXT_PUBLIC_GTM_ID && (
          <>
            <Script
              id="default-consent"
              strategy="beforeInteractive"
              dangerouslySetInnerHTML={{
                __html: `
            window.uetq = window.uetq || [];
            window.uetq.push('consent', 'default', {
              'ad_storage': 'denied'
            });
            
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}

            
            gtag("consent", "default", {
              ad_storage: "denied",
              personalization_storage: "denied",
              analytics_storage: "denied",
              functionality_storage: "granted",
              security_storage: "granted",
              wait_for_update: 500,
            });
          `,
              }}
            />
            <Script
              id="gtm"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
            
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            
            if (document.cookie.indexOf("consent=GRANTED") !== -1) {
              gtag("consent", "update", {
                ad_storage: "granted",
                personalization_storage: "denied",
                analytics_storage: "granted",
                functionality_storage: "granted",
                security_storage: "granted",
              });
              
              window.uetq = window.uetq || [];
              window.uetq.push('consent', 'update', {
                  'ad_storage': 'granted'
              });
            }
            
            if (document.cookie.indexOf("consent=DENIED") !== -1) {
              gtag("consent", "update", {
                ad_storage: "denied",
                personalization_storage: "denied",
                analytics_storage: "denied",
                functionality_storage: "granted",
                security_storage: "granted",
              });
              
              window.uetq = window.uetq || [];
              window.uetq.push('consent', 'update', {
                  'ad_storage': 'denied'
              });
            }
          `,
              }}
            />
          </>
        )}

        <ConsentProvider>
          <div className="flex min-h-screen flex-col">
            <Analytics />
            <Component {...pageProps} />
            <CookieBar />
          </div>
        </ConsentProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default BreinspokenApp;
