import { useCallback } from "react";

import Link from "next/link";

import Button from "components/Button";
import { useConsentContext } from "components/ConsentProvider";

export default function CookieBar() {
  const { state, dispatch } = useConsentContext();

  const accept = useCallback(() => {
    dispatch({
      type: "ACCEPT",
    });
  }, [dispatch]);

  const reject = useCallback(() => {
    dispatch({
      type: "REJECT",
    });
  }, [dispatch]);

  if (state.consent !== "UNKNOWN") {
    return null;
  }

  return (
    <div className="fixed bottom-0 right-0 z-20 w-full border-t bg-white p-4 drop-shadow-md sm:bottom-4 sm:right-4 sm:max-w-[350px] sm:rounded sm:border sm:p-8">
      <span className="mb-2 block font-heading text-xl font-black uppercase sm:text-2xl">Cookies</span>
      <p className="mb-6 text-sm">
        Breinspoken maakt gebruik van analytische cookies voor inzicht in het gebruik van onze website. Bekijk
        onze{" "}
        <Link href="/privacy" className="rounded-sm underline outline-none hover:no-underline focus:ring-2">
          privacy policy
        </Link>{" "}
        voor meer informatie.
      </p>

      <div className="space-y-3 sm:space-x-3 sm:space-y-0">
        <Button variant="green" onClick={accept} className="w-full justify-center sm:w-auto">
          Accepteren
        </Button>

        <Button variant="alternative" onClick={reject} className="w-full justify-center sm:w-auto">
          Afwijzen
        </Button>
      </div>
    </div>
  );
}
