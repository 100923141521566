import { createContext, useContext, useEffect, useReducer } from "react";
import type { Dispatch, ReducerAction, ReactNode } from "react";

import { addYears } from "date-fns";
import { parseCookies, setCookie } from "nookies";

export const ConsentContext = createContext<{
  state: State;
  dispatch: Dispatch<ReducerAction<typeof reducer>>;
} | null>(null);

type State = {
  consent: ConsentValue;
};

type ConsentValue = "GRANTED" | "DENIED" | "UNKNOWN";

type Action =
  | {
      type: "SET_CONSENT";
      payload: {
        consent: ConsentValue;
      };
    }
  | {
      type: "ACCEPT";
    }
  | { type: "REJECT" };

function reducer(state: State, action: Action) {
  if (action.type === "ACCEPT") {
    setCookie(undefined, "consent", "GRANTED", { expires: addYears(new Date(), 1) });

    // @ts-expect-error
    window.dataLayer = window.dataLayer || [];
    // @ts-expect-error
    function gtag() {
      // @ts-expect-error
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    }

    // @ts-expect-error
    gtag("consent", "update", {
      ad_storage: "granted",
      personalization_storage: "denied",
      analytics_storage: "granted",
      functionality_storage: "granted",
      security_storage: "granted",
    });

    // @ts-expect-error
    window.uetq = window.uetq || [];
    // @ts-expect-error
    window.uetq.push("consent", "update", {
      ad_storage: "granted",
    });

    // @ts-expect-error
    window.dataLayer = window.dataLayer || [];

    // @ts-expect-error
    window.dataLayer.push({ event: "lb-consent-trigger" });

    return {
      ...state,
      consent: "GRANTED" as ConsentValue,
    };
  } else if (action.type === "REJECT") {
    setCookie(undefined, "consent", "DENIED", { expires: addYears(new Date(), 1) });

    // @ts-expect-error
    window.dataLayer = window.dataLayer || [];
    // @ts-expect-error
    function gtag() {
      // @ts-expect-error
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    }

    // @ts-expect-error
    gtag("consent", "update", {
      ad_storage: "denied",
      personalization_storage: "denied",
      analytics_storage: "denied",
      functionality_storage: "granted",
      security_storage: "granted",
    });

    // @ts-expect-error
    window.uetq = window.uetq || [];
    // @ts-expect-error
    window.uetq.push("consent", "update", {
      ad_storage: "denied",
    });

    // @ts-expect-error
    window.dataLayer = window.dataLayer || [];

    // @ts-expect-error
    window.dataLayer.push({ event: "lb-consent-trigger" });

    return {
      ...state,
      consent: "DENIED" as ConsentValue,
    };
  } else if (action.type === "SET_CONSENT") {
    return {
      ...state,
      consent: action.payload.consent,
    };
  }

  throw Error("Unknown action");
}

export default function ConsentProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer<typeof reducer>(reducer, { consent: "UNKNOWN" });

  useEffect(() => {
    let value: ConsentValue = "UNKNOWN";

    const cookie = parseCookies().consent;

    if (cookie === "GRANTED") {
      value = "GRANTED";
    } else if (cookie === "DENIED") {
      value = "DENIED";
    }

    dispatch({
      type: "SET_CONSENT",
      payload: { consent: value },
    });
  }, []);

  return <ConsentContext.Provider value={{ state, dispatch }}>{children}</ConsentContext.Provider>;
}

export function useConsentContext() {
  const context = useContext(ConsentContext);

  if (!context) {
    throw Error("No consent context available.");
  }

  return context;
}

export function useConsentGranted(): boolean {
  const { state } = useConsentContext();

  return state.consent === "GRANTED";
}
