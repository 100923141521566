import type { AnchorHTMLAttributes, ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { forwardRef } from "react";

import Link from "next/link";

import classNames from "classnames";

const base =
  "no-underline border outline-none appearance-none text-base inline-block px-6 py-3 rounded-md group-focus:ring group-focus:ring-offset-2 focus:ring focus:ring-offset-2 before:content-[attr(title)] before:font-bold before:block before:h-0 before:overflow-hidden before:invisible";

const variants = {
  red: "bg-red ring-red border-red text-white font-bold focus:bg-red focus:font-bold focus:text-white hover:font-medium hover:text-red hover:bg-transparent",
  green:
    "bg-green ring-green border-green text-white font-bold focus:bg-green focus:font-bold focus:text-white hover:font-medium hover:text-green hover:bg-transparent",
  blue: "bg-blue ring-blue border-blue text-white font-bold focus:bg-blue focus:font-bold focus:text-white hover:font-medium hover:text-blue hover:bg-transparent",
  orange:
    "bg-orange ring-orange border-orange text-white font-bold focus:bg-orange focus:font-bold focus:text-white hover:font-medium hover:text-orange hover:bg-transparent",
  alternative:
    "bg-transparent font-medium ring-darkblue border-darkblue text-darkblue hover:bg-darkblue hover:text-white",
};

const activeVariants = {
  red: "bg-red ring-red border-red text-white font-bold",
  green: "bg-green ring-green border-green text-white font-bold",
  blue: "bg-blue ring-blue border-blue text-white font-bold",
  orange: "bg-orange ring-orange border-orange text-white font-bold",
  alternative: "bg-transparent font-medium ring-darkblue border-darkblue text-darkblue",
};

export type ButtonProps = {
  variant: "red" | "green" | "blue" | "orange" | "alternative";
  children: string;
  isActive?: boolean;
};

const Button = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps>(
  function Button({ disabled, children, variant = "red", className, isActive = false, ...props }, ref) {
    return (
      <button
        ref={ref}
        className={classNames(
          base,
          !isActive ? variants[variant] : activeVariants[variant],
          {
            "disabled:cursor-not-allowed": disabled,
          },
          className,
        )}
        title={children}
        disabled={disabled}
        {...props}
      >
        <span className={classNames("inline-flex items-center")}>{children}</span>
      </button>
    );
  },
);

export const ButtonLink = forwardRef<
  HTMLAnchorElement,
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & ButtonProps
>(function ButtonLink(
  { children, variant = "red", className, href, target, isActive = false, ...props },
  ref,
) {
  return (
    <Link
      ref={ref}
      href={href ?? ""}
      className={classNames(base, !isActive ? variants[variant] : activeVariants[variant], className)}
      target={target}
      title={children as string}
    >
      {children}
    </Link>
  );
});

export default Button;
