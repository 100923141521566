import { useEffect } from "react";

import { useRouter } from "next/router";

import { useConsentGranted } from "components/ConsentProvider";

const pageview = (url: string) => {
  // @ts-expect-error
  window.dataLayer.push({
    event: "pageview",
    page: url,
  });
};

export default function Analytics() {
  const router = useRouter();

  const granted = useConsentGranted();

  useEffect(() => {
    if (!granted || process.env.ALLOW_ROBOTS === "false") {
      return;
    }

    if (!process.env.NEXT_PUBLIC_GTM_ID) {
      console.warn("No NEXT_PUBLIC_GTM_ID is set");
      return;
    }

    router.events.on("routeChangeComplete", pageview);

    return () => {
      router.events.off("routeChangeComplete", pageview);
    };
  }, [granted, router.events]);

  return null;
}
